const IMAGES = {
  banner1: require("../img/home/banner1.jpeg"),
  banner2: require("../img/home/banner2.jpeg"),
  banner3: require("../img/home/banner3.webp"),
  banner4: require("../img/home/banner4.webp"),
  banner5: require("../img/home/banner5.jpg"),
  banner6: require("../img/home/banner6.jpg"),
};

export default IMAGES;
