import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { message } from "antd";
import axios from "axios";
import "./Contact.css";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsApp from "@mui/icons-material/WhatsApp";

const Contact = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: [],
  });
  const [error, setError] = useState(false);
  const [mapLoader, setMapLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setMapLoader(false);
    }, 1500);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "msg") {
      setForm((prevForm) => ({
        ...prevForm,
        msg: [
          {
            msg: value,
            person: "user",
          },
        ],
      }));
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    if (
      form?.name === "" ||
      form?.email === "" ||
      form?.mobile === "" ||
      form?.msg.length === 0
    ) {
      setError(true);
      return;
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: [] });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="contact-page-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 contact-image">
            <div className={`google-map loading ${mapLoader && "active"}`}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.14571458558!2d72.71637318028604!3d19.082177512541442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1712137384144!5m2!1sen!2sin"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 contact-form">
            <h5>Feel free to message us!</h5>
            <div className="hr-line mb-4"></div>
            <form onSubmit={handleSubmit}>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <input
                  onChange={handleChange}
                  name="name"
                  className=""
                  type="text"
                  value={form.name}
                  placeholder="Enter your name"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.name === "" && "Please enter your name"}
                  </small>
                </span>
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Email
                </label>
                <input
                  onChange={handleChange}
                  name="email"
                  className=""
                  type="email"
                  value={form.email}
                  placeholder="Enter your email"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.email === "" && "Please enter your email"}
                  </small>
                </span>
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Mobile
                </label>
                <input
                  onChange={handleChange}
                  name="mobile"
                  className=""
                  type="text"
                  value={form.mobile}
                  placeholder="Enter your phone number"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.mobile === "" && "Please enter your mobile"}
                  </small>
                </span>
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Query Type
                </label>
                <select
                  className="form-select"
                  name="type"
                  onChange={handleChange}
                  value={form?.type}
                >
                  <option value="">Select</option>
                  <option value="Payment Related Query">
                    Payment Related Queries
                  </option>
                  <option value="In-Game Recharge Query">
                    In-Game Recharge Query
                  </option>
                  <option value="Wanted to be a Reseller">
                    Wanted to be a Reseller
                  </option>
                  <option value="others">Other Query</option>
                </select>
                <span className="text-danger">
                  <small>
                    {error && form?.type === "" && "Select Query Type"}
                  </small>
                </span>
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Message
                </label>
                <textarea
                  onChange={handleChange}
                  className="form-control"
                  value={
                    form.msg.length > 0
                      ? form.msg[form.msg.length - 1].message
                      : ""
                  }
                  name="msg"
                  rows="3"
                  placeholder="How can we help you?"
                ></textarea>
                <span className="text-danger">
                  <small>
                    {error && form?.msg === "" && "Please enter your message"}
                  </small>
                </span>
              </div>
              <button type="submit" className="p-check-btn mt-2">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid contact-address-email">
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="https://www.google.com/maps/search/?q=India"
          style={{ textDecoration: "none", color: "black" }}
        >
          <LocationOnIcon className="icon" style={{ fontSize: "45px" }} />
          <p>
            <b>India</b>
          </p>
        </a>
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="mailto:devilsun402@gmail.com"
          style={{ textDecoration: "none", color: "black" }}
        >
          <EmailIcon className="icon" style={{ fontSize: "45px" }} />
          <p>
            <b>devilsun402@gmail.com</b>
          </p>
        </a>
        <a
          target="_blank"
          href="https://wa.me/919366479173"
          className="text-center c-address shadow m-3"
          style={{ textDecoration: "none", color: "black" }}
        >
          <WhatsApp className="icon" style={{ fontSize: "45px" }} />
          <p>
            <b>WhatsApp</b>
          </p>
        </a>
      </div>
    </Layout>
  );
};

export default Contact;
